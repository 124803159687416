<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-0 px-6 mt-n16 container-general"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-n3"
        >
          <v-card class="card-shadow v-card-grey px-0 py-0">
            <v-card-title class="pt-2 pb-2 mb-0 card-title-bloque">
              <div class="w100">
                <v-tooltip
                  top
                  color="primary"
                  class="custom-tooltip"
                  style="z-index: 11;"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="mx-2  mt-0 mb-0  float-right"
                      fab
                      small
                      color="white"
                      elevation="1"
                      v-bind="attrs"
                      @click="openVideoPlayer()"
                      v-on="on"
                    >
                      <v-icon color="light-blue darken-3">
                        fas fa-play-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Ver videotutorial') }}</span>
                </v-tooltip>

                <p class="tit_card inline-block pt-2">
                  {{ $t('Crear landing page o plantilla de mailing') }}
                </p>
              </div>
            </v-card-title>

            <div class="card-header-padding">
              <v-row>
                <v-col
                  cols="12"
                  class="mt-n3 m-10 p-10 xs"
                >
                  <landing-editor
                    v-if="landingTemplate.show"
                    :landing-template="landingTemplate"
                    :is-undo-redo="true"
                    :is-save-exit="isSaveExit"
                    @save="saveLandingTemplate"
                    @saveExit="emitSaveExit()"
                  />
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <modal-exit
      :open="openModalExit"
      :to="toModalExit"
      :name="landingTemplate.name"
      type="template"
      @close="emitClose()"
      @accept="emitAccept()"
      @save="emitSave()"
    />
    <ai-text-assistant-mailing />
  </div>
</template>

<script>
  import EventBus from '@/util/EventBus.js'
  import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
  import LandingEditor from '@/components/Template/LandingEditor/LandingEditor.vue'
  import LandingTemplateService from '@/services/landing-template.service'
  import LandingTemplate from '@/components/Template/LandingEditor/classes/LandingTemplate'
  import ModalExit from '@/components/ModalExit/ModalExit.vue'
  import AiTextAssistantMailing from '@/components/AiAssistant/AiTextAssistantMailing.vue'

  export default {
    name: 'CreateLandingTemplate',
    components: {
      HeaderTopDashboard,
      LandingEditor,
      ModalExit,
      AiTextAssistantMailing,
    },
    beforeRouteLeave (to, from, next) {
      console.log('beforeRouteLeave')
      if (to.name === 'Login') {
        next()
      } else if (this.landingTemplate.id) {
        next()
      } else if (this.routeModalExit) {
        next()
      } else {
        this.toModalExit = to
        this.openModalExit = true
        next(false)
      }
    },
    beforeRouteUpdate (to, from, next) {
      console.log('beforeRouteUpdate')
      if (to.name === 'Login') {
        next()
      } else if (this.landingTemplate.id) {
        next()
      } else if (this.routeModalExit) {
        next()
      } else {
        this.toModalExit = to
        this.openModalExit = true
        next(false)
      }
    },
    data: function () {
      return {
        landingTemplate: new LandingTemplate(),
        routeModalExit: false,
        openModalExit: false,
        toModalExit: {},
        isSaveExit: false,
      }
    },
    created: function () {
      if (this.$route.query.path) {
        LandingTemplateService.getOrCreateLanding(this.$route.query.path).then(
          (response) => {
            setTimeout(() => {
              EventBus.$emit('undoRedoManager')
            }, 100)
            this.landingTemplate.loadData(response.landingTemplate)
            if (this.$route.query.duplicate) {
              this.landingTemplate.id = null
              this.landingTemplate.name += ' - ' + this.$t('Copia')
            }
          },
          () => {},
        )
      } else {
        this.landingTemplate.show = true
      }
    },
    methods: {
      emitClose () {
        this.openModalExit = false
      },
      emitAccept () {
        this.openModalExit = false
        this.routeModalExit = true
        this.$router.push(this.toModalExit.fullPath)
      },
      emitSave () {
        this.isSaveExit = true
        this.openModalExit = false
        this.routeModalExit = true
        this.$router.push(this.toModalExit.fullPath)
      },
      emitSaveExit (landingTemplate) {
        this.saveLandingTemplate(landingTemplate)
      },
      saveLandingTemplate (landingTemplate) {
        this.landingTemplate.id = landingTemplate.id
        this.landingTemplate.name = landingTemplate.name
        this.landingTemplate.path = landingTemplate.path
      },
      openVideoPlayer () {
        const video = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 4)

        this.$store.dispatch('videoPlayer/toggle', {
          show: true,
          url: video.url,
          title: video.title,
        })
      },
    },
  }
</script>
<style scoped>
#landing-editor-container {
  width: 100%;
}
</style>
